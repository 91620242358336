import React from 'react';
import SEO from '../components/SEO.jsx';
import Header from "../partials/header/Header.jsx";
// import IntroTwo from '../container/IntroSlider/IntroTwo.js';
import HomeAboutTwo from '../components/About/HomeAboutTwo.jsx';
import Funfact from '../container/Funfact/Funfact.js';
import ServiceIconBox from '../container/service/ServiceIconBox.js';
// import HomeSkillWithVideo from '../container/HomeSkillWithVideo/HomeSkillWithVideo.js';
// import PortfolioTwo from '../container/Portfolio/PortfolioTwo.js';
// import TestimonialContainer from '../container/Testimonial/TestimonialContainer.js'
// import HomeBlog from '../container/BlogGrid/HomeBlog.js';
import ContactInformationTwo from '../container/ContactInformation/ContactInformationTwo.js';
// import BrandContainer from '../container/Brand/BrandContainer.js';
import Footer from '../container/Footer/Footer.js';
import ScrollToTop from '../components/ScrollToTop.jsx';
import IntroSlider from '../container/IntroSlider/IntroSlider.js';
import ContactInformationThree from '../container/ContactInformation/ContactInformationThree.js';




const Home = () => {
    return (
        <React.Fragment>
            <SEO title="Arch Unite | Home" />
            <Header />
            {/* <IntroTwo /> */}
            <IntroSlider />
            <HomeAboutTwo />
            <Funfact />
            <ServiceIconBox classOption="bg-color-1" />
            {/* <HomeSkillWithVideo /> */}
            {/* <PortfolioTwo /> */}
            {/* <TestimonialContainer /> */}
            {/* <HomeBlog SectionBgColor="bg-primary-blue" /> */}
            <ContactInformationThree />
            {/* <BrandContainer classOption="section-padding-bottom" /> */}
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Home;



