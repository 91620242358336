import PropTypes from "prop-types";
import React from 'react';
import IconBoxData from '../../data/iconBox/icon-box-joinus.json';
import IconBoxJoinUs from "../../components/IconBoxJoinUs/IconBoxJoinUs.jsx";

const JoinUsIconBox = ({ classOption }) => {
    return (
        <div className={`section section-padding-t90-b100 ${classOption}`}>
            <div className="container">

                <div className="section-title text-center">
                    <h2 className="title fz-32">Together We Grow</h2>
                </div>

                <div className="section-title text-center">
                    <p >At our company, we are constantly seeking talented individuals to join our team and help us achieve our goals. As a dynamic and growing organization, we believe that our success depends on the skills, dedication, and creativity of our employees. We offer a supportive and inclusive work environment, opportunities for professional growth and development, and a range of exciting projects that will challenge and inspire you. Whether you are just starting your career or are an experienced professional looking for new challenges, we welcome you to explore the opportunities available at our company.</p>
                </div>

                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">

                    {IconBoxData && IconBoxData.slice(0, 3).map((single, key) => {
                        return (
                            <div key={key} className="col mb-6" data-aos="fade-up">
                                <IconBoxJoinUs classOption="box-border" data={single} key={key} />
                            </div>
                        );
                    })}

                </div>

            </div>
        </div>
    )
}

JoinUsIconBox.propTypes = {
    classOption: PropTypes.string
};
JoinUsIconBox.defaultProps = {
    classOption: "section section-padding-t90-b100"
};

export default JoinUsIconBox;
