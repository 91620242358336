import React from 'react';
import SEO from '../components/SEO.jsx';
import Header from "../partials/header/Header.jsx";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb.js';
import FunfactTwo from '../container/Funfact/FunfactTwo.js';
import ContactInformationThree from '../container/ContactInformation/ContactInformationThree.js';
import Footer from '../container/Footer/Footer.js';
import ScrollToTop from '../components/ScrollToTop.jsx';
import ServiceIconBox from '../container/service/ServiceIconBox.js';
import JoinUsIconBox from '../container/JoinUs/JoinUsIconBox.js';


const JoinUs = () => {
    return (
        <React.Fragment>
            <SEO title="Arch Unite | Join Us" />
            <Header />
            <Breadcrumb
                image="images/bg/breadcrumb-bg-three.jpg"
                title="Provide Practical Consultation, Building with Integrity, Delivering Excellence."
                content="Home"
                contentTwo="Join Us"
            />
            <JoinUsIconBox />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default JoinUs;



